import { NgClass } from '@angular/common';
import { Component, inject, input } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { DividerModule } from 'primeng/divider';
import { GlobalStateService } from '../../../shared/services/global-state.service';

@Component({
    selector: 'app-sidebar-content',
    imports: [
        NgClass,
        DividerModule,
        RouterLink,
        RouterLinkActive,
    ],
    templateUrl: './sidebar-content.component.html',
    styleUrl: './sidebar-content.component.scss'
})
export class SidebarContentComponent {
  isVisible = input<boolean>(false);
  globalStateService = inject(GlobalStateService);
  menus = this.globalStateService.currentMenus;
}
