import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class UserService {
  private readonly http = inject(HttpClient);

  validateSubscription() {
    return this.http.get<any>("/api/user/subscription/validate-subscription", {withCredentials: true, responseType: 'json'});
  }
}
