import { inject } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { UserService } from "../../services/user.service";
import { userAction } from "./user.action";
import { catchError, map, of, switchMap } from "rxjs";

export const userEffect = createEffect((action = inject(Actions), userService = inject(UserService)) => {
  return action.pipe(
    ofType(userAction.validateSubscription),
    switchMap(() => {
      return userService.validateSubscription().pipe(
        map((response) => {
          return userAction.validateSubscriptionSuccess(response);
        }),
        catchError((error) => {
          return of(userAction.validateSubscriptionFailure({ errorMessage: error.error.message }));
        })
      );
    })
  );
}, {
    functional: true
});
