import { inject } from "@angular/core";
import { createEffect, Actions, ofType } from "@ngrx/effects";
import { switchMap, map, catchError, of } from "rxjs";
import { settingActions } from "./setting.action";
import { SettingService } from "../setting.service";

export const getUserProfileEffect = createEffect(
  (actions$ = inject(Actions), settingService = inject(SettingService)) => {
    return actions$.pipe(
      ofType(settingActions.getUserProfile),
      switchMap(() => {
        return settingService.getUserProfile().pipe(
          map((res) => {
            return settingActions.getUserProfileSuccess({ userProfile: res });
          }),
          catchError((err) => {
            return of(settingActions.getUserProfileFailure({ error: err.error.message }));
          })
        );
      })
    );
  },
  {
    functional: true,
  }
);
