import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { UserProfile } from "./setting.type";

@Injectable({
  providedIn: "root",
})
export class SettingService {
    private http = inject(HttpClient);
  constructor() {}

  getUserProfile() {
    return this.http.get<UserProfile>("/api/user/setting/profile");
  }
}