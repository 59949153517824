import { Component, computed, HostListener, inject, signal } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { SidebarModule } from 'primeng/sidebar';

import { ResizerComponent } from '../resizer/resizer.component';
import { SidebarContentComponent } from '../sidebar-content/sidebar-content.component';
import { ToggleSidebarDirective } from '../../directives/toggle-sidebar.directive';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgClass } from '@angular/common';
@Component({
    selector: 'app-sidebar',
    imports: [
        SidebarModule,
        ButtonModule,
        ToggleSidebarDirective,
        ResizerComponent,
        SidebarContentComponent,
        NgClass,
    ],
    templateUrl: './sidebar.component.html',
    styleUrl: './sidebar.component.scss'
})
export class SidebarComponent {
  hoverToggle = signal(false);
  btnToggle = signal(true);
  deviceDetectorService = inject(DeviceDetectorService);
  isMobile = this.deviceDetectorService.isMobile();

  isSidebarOpen = computed(() => this.hoverToggle() || this.btnToggle());

  toggle() {
    this.btnToggle.update((val) => !val);
  }

  toggleSidebar(event: boolean) {
    this.hoverToggle.set(event);
  }
}
