import { InputSwitchModule } from 'primeng/inputswitch';
import { Component, inject, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { HeaderComponent } from './core/components/header/header.component';
import { SidebarComponent } from './core/components/sidebar/sidebar.component';
import { NgToastModule, ToasterPosition } from 'ng-angular-popup';
import { Store } from '@ngrx/store';
import { userAction } from './shared/store/user/user.action';
import { GlobalStateService } from './shared/services/global-state.service';

@Component({
    selector: 'app-root',
    imports: [
        RouterOutlet,
        InputSwitchModule,
        FormsModule,
        HeaderComponent,
        SidebarComponent,
        NgToastModule,
    ],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {

  ToastPosition = ToasterPosition;
  store = inject(Store);
  globalStateService = inject(GlobalStateService);

  ngOnInit(): void {
    if(this.globalStateService.isLoggedIn() || this.globalStateService.userInfo().email) {
      this.store.dispatch(userAction.validateSubscription())
    }
  }

}
