import { Routes } from "@angular/router";
import { authGuard } from "./shared/guards/auth.guard";
import { provideState } from "@ngrx/store";
import { settingFeature } from "./pages/setting/store/setting.reducer";
import { provideEffects } from "@ngrx/effects";
import * as settingEffect from "./pages/setting/store/setting.effect";

export const routes: Routes = [
  {
    path: "",
    redirectTo: "home",
    pathMatch: "full",
  },
  {
    path: "home",
    loadComponent: () => import("./pages/home/home.component").then((m) => m.HomeComponent),
  },
  {
    path: "history",
    loadComponent: () => import("./pages/history/history.component").then((m) => m.HistoryComponent),
  },
  {
    path: "setting",
    providers: [provideState(settingFeature.name, settingFeature.reducer), provideEffects(settingEffect)],
    loadComponent: () => import("./pages/setting/setting.component").then((m) => m.SettingComponent),
  },
  {
    path: "dashboard",
    loadComponent: () => import("./pages/dashboard/dashboard.component").then((m) => m.DashboardComponent),
  },
  {
    path: "content-management/:slug",
    loadComponent: () => import("./pages/content-management/content-management.component").then((m) => m.ContentManagementComponent),
    canActivate: [authGuard],
  },
  {
    path: "auth",
    loadChildren: () => import("./pages/auth/auth.routes").then((m) => m.AUTH_ROUTES),
  },
  {
    path: "success",
    loadComponent: () => import("./pages/payment/payment-redirect/payment-redirect.component").then((m) => m.PaymentRedirectComponent),
  },
  {
    path: "cancel",
    loadComponent: () => import("./pages/payment/cancel/cancel.component").then((m) => m.CancelComponent),
  },
  {
    path: "payment-success",
    loadComponent: () => import("./pages/payment/success/success.component").then((m) => m.SuccessComponent),
  },
  {
    path: "payment-failed",
    loadComponent: () => import("./pages/payment/failed/failed.component").then((m) => m.FailedComponent),
  },
];
