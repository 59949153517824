import { NgClass } from '@angular/common';
import { Component, EventEmitter, HostListener, Output, output, signal } from '@angular/core';

@Component({
    selector: 'app-resizer',
    imports: [NgClass],
    templateUrl: './resizer.component.html',
    styleUrl: './resizer.component.scss'
})
export class ResizerComponent {
  btnToggle = signal(false);
  isActive = signal(false);
  @HostListener('mouseenter', ['$event'])
  onMouseEnter(event: Event) {
    this.isActive.set(true);
  }

  @HostListener('mouseleave', ['$event'])
  onMouseLeave(event: Event) {
    this.isActive.set(false);
  }
  handleToggleBtn = output<boolean>();
  toggle() {
    this.btnToggle.update((prev) => !prev);
    this.handleToggleBtn.emit(this.btnToggle());
  }
}
