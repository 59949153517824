import { Component, inject, OnInit } from "@angular/core";
import { LogoComponent } from "../logo/logo.component";
import { ProfileComponent } from "../profile/profile.component";
import { GlobalStateService } from "../../../shared/services/global-state.service";

@Component({
    selector: "app-header",
    imports: [LogoComponent, ProfileComponent],
    templateUrl: "./header.component.html",
    styleUrl: "./header.component.scss"
})
export class HeaderComponent implements OnInit {
  private globalStateService = inject(GlobalStateService);

  ngOnInit(): void {
    const computeIsLoggedIn = !!this.globalStateService.user()?.email || !!localStorage.getItem("access_token");
    this.globalStateService.isLoggedIn.set(computeIsLoggedIn);
  }
}
