import { DOCUMENT } from '@angular/common';
import { Component, inject, signal } from '@angular/core';

@Component({
    selector: 'app-theme-switcher',
    imports: [],
    templateUrl: './theme-switcher.component.html',
    styleUrl: './theme-switcher.component.scss'
})
export class ThemeSwitcherComponent {
  isDarkMode = signal(true);
  private document = inject(DOCUMENT);
  toggleLightDark() {
    const linkElement = this.document.getElementById(
      'app-theme'
    ) as HTMLLinkElement;
    if (linkElement.href.includes('light')) {
      linkElement.href = 'theme-dark.css';
      this.document.body.classList.remove('light');
      this.document.body.classList.add('dark');
      this.isDarkMode.set(true);
    } else {
      linkElement.href = 'theme-light.css';
      this.isDarkMode.set(false);
      this.document.body.classList.remove('dark');
      this.document.body.classList.add('light');
    }
  }
}
